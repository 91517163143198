import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import React from 'react';
import dynamic from 'next/dynamic';
import useSWR from 'swr';

const Services = dynamic(() => import('./Services'));
const Pricing = dynamic(() => import('./Pricing'));
const Support = dynamic(() => import('./Support'));
const Index = dynamic(() => import('./Index'));
const FAQ = dynamic(() => import('./FAQ'));
const ServiceItem = dynamic(() => import('./ServiceItem'));

interface Data {
  _id: string;
  available: boolean;
  name: string;
  slides: [];
  serviceItem: [];
  logoPath: string;
  mainServices: string;
  mainServices1: string;
  mainServices1details: string;
  mainServices2: string;
  mainServices2details: string;
  mainServices3: string;
  mainServices3details: string;
  mainServices4: string;
  mainServices4details: string;
  mainServices5: string;
  mainServices5details: string;
  pricing: string;
  pricingDetails: string;
  pricingButton: string;
  support: string;
  support1: string;
  support1details: string;
  support2: string;
  support2details: string;
  faqs: [];
  faqsTitle: string;
  services: string;
  services1: string;
  services2: string;
  services3: string;
  services4: string;
  services5: string;
  links: string;
  links1: string;
  links1title: string;
  links2: string;
  links2title: string;
  links3: string;
  links3title: string;
  contact: string;
  phone: string;
  whatsappLink: string;
  whatsappNumber: string;
  email: string;
  facebookLink: string;
  twitterLink: string;
  instagramLink: string;
  __v: number;
}

const fetcher = (url: string) => fetch(url).then(res => res.json());

const Footer: React.FC = () => {
  const { data, error } = useSWR<Data>('/api/setting', fetcher);

  if (error) {
    console.error('Error fetching data:', error);
    return <div>Error loading data</div>;
  }

  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <main>

      <Index
        slides={data.slides}
      />

      <Services
        logoPath={data.logoPath}
        mainServices={data.mainServices}
        mainServices1={data.mainServices1}
        mainServices1details={data.mainServices1details}
        mainServices2={data.mainServices2}
        mainServices2details={data.mainServices2details}
        mainServices3={data.mainServices3}
        mainServices3details={data.mainServices3details}
        mainServices4={data.mainServices4}
        mainServices4details={data.mainServices4details}
        mainServices5={data.mainServices5}
        mainServices5details={data.mainServices5details}
      />

      <Pricing
        pricing={data.pricing}
        pricingDetails={data.pricingDetails}
        pricingButton={data.pricingButton}
      />

      <Support
        support={data.support}
        support1={data.support1}
        support1details={data.support1details}
        support2={data.support2}
        support2details={data.support2details}
      />

      <FAQ
        faqsTitle={data.faqsTitle}
        faqs={data.faqs}
      />
 
      <ServiceItem
        serviceItem={data.serviceItem}
      />

      <footer className="py-4 text-white mt-10" style={{ backgroundColor: 'var(--iabcolour)' }}>
        <div className="container mx-auto text-center grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="mb-4">
            <h2 className="text-xl font-semibold mb-2">{data.services}</h2>
            <ul className="list-none">
              <li><a className="text-white" href="#">{data.services1}</a></li>
              <li><a className="text-white" href="#">{data.services2}</a></li>
              <li><a className="text-white" href="#">{data.services3}</a></li>
              <li><a className="text-white" href="#">{data.services4}</a></li>
              <li><a className="text-white" href="#">{data.services5}</a></li>
            </ul>
          </div>

          <div className="mb-8 md:mb-4">
            <h2 className="text-xl font-semibold mb-2">{data.links}</h2>
            <ul className="list-none">
              <li>
                <a href={data.links1} className="text-white px-4 py-2">{data.links1title}</a>
              </li>
              <li>
                <a href={data.links2} className="text-white px-4 py-2">{data.links2title}</a>
              </li>
              <li>
                <a href={data.links3} className="text-white px-4 py-2">{data.links3title}</a>
              </li>
            </ul>
          </div>

          <div className="mb-4">
            <h2 className="text-xl font-semibold mb-2">{data.contact}</h2>
            <p>
              {data.phone && (
                <>
                  <FontAwesomeIcon icon={faPhone} className="mr-2" />
                  {data.phone}
                </>
              )}
            </p>
            <p>
              {data.whatsappNumber && (
                <>
                  <FontAwesomeIcon icon={faWhatsapp} className="mr-2" />
                  <a className="text-white" href={data.whatsappLink} target="_blank" rel="noopener noreferrer">{data.whatsappNumber}</a>
                </>
              )}
            </p>
            <p>
              {data.email && (
                <>
                  <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
                  <a className="text-white" href={`mailto:${data.email}`}>{data.email}</a>
                </>
              )}
            </p>
            <SocialMediaLinks 
              facebookLink={data.facebookLink || ""} 
              twitterLink={data.twitterLink || ""} 
              instagramLink={data.instagramLink || ""} 
            />
          </div>
        </div>

        <div className="container mx-auto text-center mt-4">
          <p>&copy; {new Date().getFullYear()} IAB CODES LTD. All rights reserved.</p>
        </div>
      </footer>
    </main>
  );
};

interface SocialMediaLinksProps {
  facebookLink: string;
  twitterLink: string;
  instagramLink: string;
}

const SocialMediaLinks: React.FC<SocialMediaLinksProps> = ({ facebookLink, twitterLink, instagramLink }) => {
  return (
    <div className="flex justify-center space-x-4 mt-4">
      {facebookLink && (
        <a href={facebookLink} target="_blank" rel="noopener noreferrer" aria-label="Facebook">
          <FontAwesomeIcon icon={faFacebook} className="text-white text-2xl" />
        </a>
      )}
      {twitterLink && (
        <a href={twitterLink} target="_blank" rel="noopener noreferrer" aria-label="Twitter">
          <FontAwesomeIcon icon={faTwitter} className="text-white text-2xl" />
        </a>
      )}
      {instagramLink && (
        <a href={instagramLink} target="_blank" rel="noopener noreferrer" aria-label="Instagram">
          <FontAwesomeIcon icon={faInstagram} className="text-white text-2xl" />
        </a>
      )}
    </div>
  );
};

export default Footer;
