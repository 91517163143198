import React, { useState, useEffect, useRef } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faBars, faCode, faBook, faSignInAlt, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { useRouter } from 'next/router';

const Header1 = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {isMobile ? <MobileHeader /> : <DesktopHeader />}
    </>
  );
};

const MobileHeader = () => {
  const [isOpen, setIsOpen] = useState(false);
  const sidebarRef = useRef<HTMLDivElement | null>(null);
  const router = useRouter();

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <header className="bg-white py-4 flex justify-between items-center fixed w-full top-0 z-10">
      <div className="pr-4">
        <Link href="/">
          <Image
            src={`/logo.jpg?v=${new Date().getTime()}`}
            alt="iabcodes"
            width={200}
            height={100}
            className="ml-4 h-20 w-auto"
          />
        </Link>
      </div>
      {!isOpen && <div className="w-60"></div>}
      <div ref={sidebarRef} className={`fixed-sidebar fixed top-0 right-0 h-full transition-all duration-300 ease-in-out ${isOpen ? 'w-60' : 'w-0'}`}>
        <button className="px-4 py-6 focus:outline-none absolute right-0 mt-4 mr-4" onClick={toggleSidebar} aria-label={isOpen ? 'Close Sidebar' : 'Open Sidebar'}>
          <FontAwesomeIcon icon={isOpen ? faTimes : faBars} size="lg" className="bg-iabcolour toggle-button rounded p-2" />
        </button>
        {isOpen && (
          <nav className="py-28 w-60">
            <ul>
              <li className="py-2 pl-6 border-t border-gray-300 hover:bg-gray-300">
                <Link href="/blog" className="flex items-center">
                  <FontAwesomeIcon icon={faBook} className="mr-2" />
                  Blog
                </Link>
              </li>
              <li className="py-2 pl-6 border-t border-gray-300 hover:bg-gray-300">
                <Link href="/signup" className="flex items-center">
                  <FontAwesomeIcon icon={faUserPlus} className="mr-2" />
                  Signup
                </Link>
              </li>
              <li className="py-2 pl-6 border-t border-gray-300 hover:bg-gray-300">
                <Link href="/login" className="flex items-center">
                  <FontAwesomeIcon icon={faSignInAlt} className="mr-2" />
                  Login
                </Link>
              </li>
              <li className="py-2 pl-6 border-t border-gray-300 hover:bg-gray-300">
                <Link href="/documentation" className="flex items-center">
                  <FontAwesomeIcon icon={faCode} className="mr-2" />
                  API documentation
                </Link>
              </li>
            </ul>
          </nav>
        )}
      </div>
    </header>
  );
};

const DesktopHeader = () => {
  return (
    <header className="bg-white py-4 flex justify-between items-center fixed w-full top-0 z-10">
      <div className="ml-4">
        <Link href="/">
          <Image
            src={`/logo.jpg?v=${new Date().getTime()}`}
            alt="iabcodes"
            width={200}
            height={100}
            className="h-20 w-auto"
          />
        </Link>
      </div>
      <nav className="flex items-center space-x-6 pr-4">
        <Link href="/blog" className="text-iabcolour hover:text-iabcolour1">
          <FontAwesomeIcon icon={faBook} className="mr-1" />
          Blog
        </Link>
        <Link href="/signup" className="text-iabcolour hover:text-iabcolour1">
          <FontAwesomeIcon icon={faUserPlus} className="mr-1" />
          Signup
        </Link>
        <Link href="/login" className="text-iabcolour hover:text-iabcolour1">
          <FontAwesomeIcon icon={faSignInAlt} className="mr-1" />
          Login
        </Link>
        <Link href="/documentation" className="text-iabcolour hover:text-iabcolour1">
          <FontAwesomeIcon icon={faCode} className="mr-1" />
          API documentation
        </Link>
      </nav>
    </header>
  );
};

export default Header1;
